import { createSlice } from "@reduxjs/toolkit";
import valuationApi from "./valuationApi";
import valuationCategoriesApi from "./valuationCategoriesApi";
import valuationPropertyDefinitionsApi from "./valuationPropertyDefinitionsApi";
import valuationRealEstateObjectsApi from "./valuationRealEstateObjectsApi";
import valuationApprovalsApi from "./valuationApprovalsApi";
import valuationCoiEntriesApi from "./valuationCoiEntriesApi";
import valuationPortfoliosApi from "./valuationPortfoliosApi";
import valuationClientsApi from "./valuationClientsApi";
import valuationPredefinedTextApi from "./valuationPredefinedTextApi";
import valuationRealEstateObjectDefinitionsApi from "./valuationRealEstateObjectDefinitionsApi";
import valuationTransactionsApi from "./valuationTransactionsApi";
import valuationSuggestionsApi from "./valuationSuggestionsApi";
import valuationPropertyNotesApi from "./valuationPropertyNotesApi";

const initialState = {
    loading: false,
    isLoaded: false,
    entities: undefined,
    valuationId: undefined,
    activeCategoryId: undefined,
    error: undefined,
    currentRequestId: undefined,
    propertyDefinitions: {
        loading: false,
        isLoaded: false,
        entities: {},
        currentRequestId: undefined,
        error: undefined,
    },
    categories: {
        loading: false,
        isLoaded: false,
        ids: undefined,
        entities: undefined,
        currentRequestId: undefined,
        error: undefined,
    },
    predefinedText: { loading: false, entities: undefined, currentRequestId: undefined, error: undefined },
    realEstateObjects: { loading: false, entities: undefined, currentRequestId: undefined, error: undefined },
    realEstateObjectDefinitions: { loading: false, entities: [], currentRequestId: undefined, error: undefined },
    portfolios: {
        loading: false,
        isLoaded: false,
        entities: undefined,
        currentRequestId: undefined,
        error: undefined,
    },
    clients: {
        loading: false,
        isLoaded: false,
        entities: undefined,
        currentRequestId: undefined,
        error: undefined,
    },
    notes: {
        loading: false,
        isLoaded: false,
        entities: undefined,
        isUpdating: false,
        error: undefined,
        currentRequestId: undefined,
    },
    status: {
        approvals: {
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
        },
        coiEntries: {
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
        },
    },
    transactions: {
        loading: false,
        entities: undefined,
        currentRequestId: undefined,
        error: undefined,
        columns: [
            {
                id: 1,
                key: "drag",
                title: undefined,
            },
            {
                id: 2,
                key: "link",
                title: undefined,
            },
            {
                id: 3,
                key: "type",
                title: "transaction.transactionType",
            },
            {
                id: 4,
                key: "image",
                title: "transaction.photo",
            },
            {
                id: 5,
                key: "address",
                title: "transaction.address",
            },
            {
                id: 6,
                key: "sqmPrice",
                title: "valuation.transaction.price_per_meter",
            },
            {
                id: 7,
                key: "date",
                title: "transaction.date",
            },
            {
                id: 8,
                key: "delete",
                title: undefined,
            },
        ],
    },
    uiStyles: {
        headerHeight: 0,
        contentPadding: 0,
    },
    suggestions: {
        loading: false,
        isLoaded: false,
        entities: undefined,
        currentRequestId: undefined,
        error: undefined,
    },
    agrimeter: {
        showModal: false,
    },
};

const valuationEdit = createSlice({
    name: "valuationEdit",
    initialState,
    reducers: {
        setValuationId: (state, action) => {
            const newState = state;
            newState.valuationId = action.payload;
        },
        setActiveCategoryId: (state, action) => {
            const newState = state;
            newState.activeCategoryId = action.payload;
        },
        updateAddress: (state, action) => {
            const newState = state;

            newState.entities.address = action.payload;
        },
        updateDaysRemaining: (state, action) => {
            const newState = state;
            newState.entities.daysRemaining = action.payload;

            return newState;
        },
        updateMainImage: (state, action) => {
            const newState = state;
            const { available, lastUpdated } = action.payload;

            newState.entities.mainImage.available = available;
            newState.entities.mainImage.lastUpdated = lastUpdated;

            return newState;
        },
        updateVersion: (state, action) => {
            const newState = state;
            newState.entities.version = action.payload;

            return newState;
        },
        updatePropertyDefinition: (state, action) => {
            const newState = state;
            const { id, ...propertyDefinition } = action.payload;
            const currentValue = newState.propertyDefinitions.entities[id];
            newState.propertyDefinitions.entities[id] = {
                ...currentValue,
                ...propertyDefinition,
            };

            return newState;
        },
        setPropertyDefinitionUpdate: (state, action) => {
            const newState = state;
            const { id, value } = action.payload;

            if (value) {
                newState.propertyDefinitions.entities[id].value = value;
            }

            newState.propertyDefinitions.entities[id].updating = true;

            return newState;
        },
        setPortfolioUpdate: (state, action) => {
            const newState = state;
            const updating = action.payload;

            newState.entities.portfolio.updating = updating;

            return newState;
        },
        updatePortfolio: (state, action) => {
            const newState = state;
            const { payload } = action || {};

            newState.entities.portfolio = payload;

            return newState;
        },
        setClientUpdate: (state, action) => {
            const newState = state;
            const updating = action.payload;

            newState.entities.client.updating = updating;

            return newState;
        },
        updateClient: (state, action) => {
            const newState = state;
            const { payload } = action || {};
            const currentClientObject = newState.entities.client;
            const defaultClientObject = {
                updating: false,
                mandatory: true,
            };

            if (payload.error) {
                newState.entities.client = {
                    ...currentClientObject,
                    ...payload,
                };
                return newState;
            }

            newState.entities.client = {
                ...defaultClientObject,
                ...payload,
            };

            return newState;
        },
        updateRealEstateObjectDefinition: (state, action) => {
            const newState = state;
            const { id, value, categoryId, updating } = action.payload;

            newState?.realEstateObjectDefinitions?.entities?.map((item) => {
                item.reoDefinitions?.map((category) => {
                    if (category.id === categoryId) {
                        category.properties.map((property) => {
                            const tempProperty = property;
                            if (property.id === id) {
                                tempProperty.value = value;
                                tempProperty.updating = updating;
                            }

                            return property;
                        });
                    }

                    return category;
                });
                return item;
            });
            return newState;
        },
        setRealEstatePropertyDefinitionUpdate: (state, action) => {
            const newState = state;
            const { id, objectId } = action.payload;

            newState?.realEstateObjectDefinitions?.entities?.map((item) => {
                if (item.objectId === objectId) {
                    item.reoDefinitions.map((category) => {
                        category.properties.map((property) => {
                            if (property.id === id) {
                                const tempProperty = property;
                                tempProperty.updating = true;
                            }
                            return property;
                        });
                        return category;
                    });
                }
                return item;
            });
            return newState;
        },
        updateTransactions: (state, action) => {
            const newState = state;
            const { transactions, updating, error } = action.payload || {};

            newState.transactions.entities = transactions;
            newState.transactions.updating = updating;
            newState.transactions.error = error;

            return newState;
        },
        setTransactionsUpdate: (state, action) => {
            const newState = state;
            const updating = action.payload;

            newState.transactions.updating = updating;

            return newState;
        },
        setIsLoaded: (state) => {
            const newState = state;
            newState.isLoaded = true;
        },
        setUiStyles: (state, action) => {
            const newState = state;
            const currentUiStyles = state.uiStyles;

            newState.uiStyles = {
                ...currentUiStyles,
                ...action.payload,
            };
            return newState;
        },
        updateNote: (state, action) => {
            const { noteId, resolved, note } = action.payload;
            const newState = state;
            const currentNote = state.notes.entities?.find((x) => x.id === noteId);

            if (resolved) currentNote.resolved = resolved;
            if (note) currentNote.note = note;
            newState.notes.entites = { ...state.notes.entities, currentNote };

            return newState;
        },
        setAreNotesUpdating: (state, action) => {
            const newState = state;
            newState.notes.isUpdating = action.payload;
        },
        resetValuation: () => {
            return initialState;
        },
        setIsSuggestionTableRendered: (state, action) => {
            const { propertyId, status } = action.payload;
            const selectedSuggestion = state.suggestions.entities.find(
                (suggestion) => suggestion.propertyDefinitionId === propertyId
            );
            if (selectedSuggestion) selectedSuggestion.isTableRendered = status;
        },
        setIsSuggestionFlagged: (state, action) => {
            const { propertyId, isFlagged, hash } = action.payload;
            const selectedSuggestion = state.suggestions.entities.find(
                (suggestion) => suggestion.propertyDefinitionId === propertyId
            );
            const selectedRow = selectedSuggestion.comparableData.find((suggestion) => suggestion.hash === hash);
            selectedRow.flagged = isFlagged;
        },
        setIsAgrimeterModalVisible: (state, action) => {
            const newState = state;
            newState.agrimeter.showModal = action.payload;
        },
    },
    extraReducers: {
        [valuationApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [valuationApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.isLoaded = true;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [valuationApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
        [valuationCategoriesApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.categories.loading === false && !state.categories.currentRequestId) {
                newState.categories.loading = true;
                newState.categories.currentRequestId = requestId;
            }
        },
        [valuationCategoriesApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.categories.loading === true && state.categories.currentRequestId === requestId) {
                const { categories, propertiesBySubCategory } = action.payload;
                newState.categories.loading = false;
                newState.categories.isLoaded = true;
                newState.categories.entities = categories.entities;
                newState.categories.items = categories.items;
                newState.categories.propertiesBySubCategory = propertiesBySubCategory;
                newState.categories.currentRequestId = undefined;
            }
        },
        [valuationCategoriesApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.categories.loading === true && state.categories.currentRequestId === requestId) {
                newState.categories.loading = false;
                newState.categories.isLoaded = true;
                newState.categories.error = action.payload?.error;
                newState.categories.currentRequestId = undefined;
            }
        },
        [valuationPropertyDefinitionsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.propertyDefinitions.loading === false && !state.propertyDefinitions.currentRequestId) {
                newState.propertyDefinitions.loading = true;
                newState.propertyDefinitions.currentRequestId = requestId;
            }
        },
        [valuationPropertyDefinitionsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (
                state.propertyDefinitions.loading === true &&
                state.propertyDefinitions.currentRequestId === requestId
            ) {
                newState.propertyDefinitions.loading = false;
                newState.propertyDefinitions.isLoaded = true;
                newState.propertyDefinitions.entities = action.payload;
                newState.propertyDefinitions.currentRequestId = undefined;
            }
        },
        [valuationPropertyDefinitionsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (
                state.propertyDefinitions.loading === true &&
                state.propertyDefinitions.currentRequestId === requestId
            ) {
                newState.propertyDefinitions.loading = false;
                newState.propertyDefinitions.isLoaded = true;
                newState.propertyDefinitions.error = action.payload?.error;
                newState.propertyDefinitions.currentRequestId = undefined;
            }
        },
        [valuationRealEstateObjectsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.realEstateObjects.loading === false && !state.realEstateObjects.currentRequestId) {
                newState.realEstateObjects.loading = true;
                newState.realEstateObjects.currentRequestId = requestId;
            }
        },
        [valuationRealEstateObjectsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.realEstateObjects.loading === true && state.realEstateObjects.currentRequestId === requestId) {
                newState.realEstateObjects.loading = false;
                newState.realEstateObjects.entities = action.payload;
                newState.realEstateObjects.currentRequestId = undefined;
            }
        },
        [valuationRealEstateObjectsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.realEstateObjects.loading === true && state.realEstateObjects.currentRequestId === requestId) {
                newState.realEstateObjects.loading = false;
                newState.realEstateObjects.error = action.payload?.error;
                newState.realEstateObjects.currentRequestId = undefined;
            }
        },
        [valuationApprovalsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.approvals.loading === false && !state.status.approvals.currentRequestId) {
                newState.status.approvals.loading = true;
                newState.status.approvals.currentRequestId = requestId;
            }
        },
        [valuationApprovalsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.approvals.loading === true && state.status.approvals.currentRequestId === requestId) {
                newState.status.approvals.loading = false;
                newState.status.approvals.entities = action.payload;
                newState.status.approvals.currentRequestId = undefined;
            }
        },
        [valuationApprovalsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.approvals.loading === true && state.status.approvals.currentRequestId === requestId) {
                newState.status.approvals.loading = false;
                newState.status.approvals.error = action.payload?.error;
                newState.status.approvals.currentRequestId = undefined;
            }
        },
        [valuationCoiEntriesApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.coiEntries.loading === false && !state.status.coiEntries.currentRequestId) {
                newState.status.coiEntries.loading = true;
                newState.status.coiEntries.currentRequestId = requestId;
            }
        },
        [valuationCoiEntriesApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.coiEntries.loading === true && state.status.coiEntries.currentRequestId === requestId) {
                newState.status.coiEntries.loading = false;
                newState.status.coiEntries.entities = action.payload;
                newState.status.coiEntries.currentRequestId = undefined;
            }
        },
        [valuationCoiEntriesApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.status.coiEntries.loading === true && state.status.coiEntries.currentRequestId === requestId) {
                newState.status.coiEntries.loading = false;
                newState.status.coiEntries.error = action.payload?.error;
                newState.status.coiEntries.currentRequestId = undefined;
            }
        },
        [valuationPortfoliosApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.portfolios.loading === false && !state.portfolios.currentRequestId) {
                newState.portfolios.loading = true;
                newState.portfolios.currentRequestId = requestId;
            }
        },
        [valuationPortfoliosApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.portfolios.loading === true && state.portfolios.currentRequestId === requestId) {
                newState.portfolios.loading = false;
                newState.portfolios.isLoaded = true;
                newState.portfolios.entities = action.payload;
                newState.portfolios.currentRequestId = undefined;
            }
        },
        [valuationPortfoliosApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.portfolios.loading === true && state.portfolios.currentRequestId === requestId) {
                newState.portfolios.loading = false;
                newState.portfolios.isLoaded = true;
                newState.portfolios.error = action.payload?.error;
                newState.portfolios.currentRequestId = undefined;
            }
        },
        [valuationClientsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.clients.loading === false && !state.clients.currentRequestId) {
                newState.clients.loading = true;
                newState.clients.currentRequestId = requestId;
            }
        },
        [valuationClientsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;
            const { clients, selectOptions } = action.payload || {};

            if (state.clients.loading === true && state.clients.currentRequestId === requestId) {
                newState.clients.loading = false;
                newState.clients.isLoaded = true;
                newState.clients.entities = clients;
                newState.clients.selectOptions = selectOptions;
                newState.clients.currentRequestId = undefined;
            }
        },
        [valuationClientsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.clients.loading === true && state.clients.currentRequestId === requestId) {
                newState.clients.loading = false;
                newState.clients.isLoaded = true;
                newState.clients.error = action.payload?.error;
                newState.clients.currentRequestId = undefined;
            }
        },
        [valuationPredefinedTextApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.predefinedText.loading === false && !state.predefinedText.currentRequestId) {
                newState.predefinedText.loading = true;
                newState.predefinedText.currentRequestId = requestId;
            }
        },
        [valuationPredefinedTextApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.predefinedText.loading === true && state.predefinedText.currentRequestId === requestId) {
                newState.predefinedText.loading = false;
                newState.predefinedText.entities = action.payload;
                newState.predefinedText.currentRequestId = undefined;
            }
        },
        [valuationPredefinedTextApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.predefinedText.loading === true && state.predefinedText.currentRequestId === requestId) {
                newState.predefinedText.loading = false;
                newState.predefinedText.error = action.payload?.error;
                newState.predefinedText.currentRequestId = undefined;
            }
        },
        [valuationRealEstateObjectDefinitionsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (
                state.realEstateObjectDefinitions.loading === false &&
                !state.realEstateObjectDefinitions.currentRequestId
            ) {
                newState.realEstateObjectDefinitions.loading = true;
                newState.realEstateObjectDefinitions.currentRequestId = requestId;
            }
        },
        [valuationRealEstateObjectDefinitionsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;
            if (
                state.realEstateObjectDefinitions.loading === true &&
                state.realEstateObjectDefinitions.currentRequestId === requestId
            ) {
                newState.realEstateObjectDefinitions.loading = false;
                newState.realEstateObjectDefinitions.entities = [
                    ...newState.realEstateObjectDefinitions.entities,
                    action.payload,
                ];
                newState.realEstateObjectDefinitions.currentRequestId = undefined;
            }
        },
        [valuationRealEstateObjectDefinitionsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (
                state.realEstateObjectDefinitions.loading === true &&
                state.realEstateObjectDefinitions.currentRequestId === requestId
            ) {
                newState.realEstateObjectDefinitions.loading = false;
                newState.realEstateObjectDefinitions.error = action.payload?.error;
                newState.realEstateObjectDefinitions.currentRequestId = undefined;
            }
        },
        [valuationTransactionsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.transactions.loading === false && !state.transactions.currentRequestId) {
                newState.transactions.loading = true;
                newState.transactions.currentRequestId = requestId;
            }
        },
        [valuationTransactionsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;
            if (state.transactions.loading === true && state.transactions.currentRequestId === requestId) {
                newState.transactions.loading = false;
                newState.transactions.entities = action.payload;
                newState.transactions.currentRequestId = undefined;
            }
        },
        [valuationTransactionsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.transactions.loading === true && state.transactions.currentRequestId === requestId) {
                newState.transactions.loading = false;
                newState.transactions.error = action.payload?.error;
                newState.transactions.currentRequestId = undefined;
            }
        },
        [valuationSuggestionsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.suggestions.loading === false && !state.suggestions.currentRequestId) {
                newState.suggestions.loading = true;
                newState.suggestions.currentRequestId = requestId;
            }
        },
        [valuationSuggestionsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;
            if (state.suggestions.loading === true && state.suggestions.currentRequestId === requestId) {
                newState.suggestions.loading = false;
                newState.suggestions.entities = action.payload;
                newState.suggestions.currentRequestId = undefined;
            }
        },
        [valuationSuggestionsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.suggestions.loading === true && state.suggestions.currentRequestId === requestId) {
                newState.suggestions.loading = false;
                newState.suggestions.error = action.payload?.error;
                newState.suggestions.currentRequestId = undefined;
            }
        },
        [valuationPropertyNotesApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.notes.loading === false && !state.notes.currentRequestId) {
                newState.notes.loading = true;
                newState.notes.currentRequestId = requestId;
            }
        },
        [valuationPropertyNotesApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;
            if (state.notes.loading === true && state.notes.currentRequestId === requestId) {
                newState.notes.loading = false;
                newState.notes.entities = action.payload;
                newState.notes.currentRequestId = undefined;
            }
        },
        [valuationPropertyNotesApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.notes.loading === true && state.notes.currentRequestId === requestId) {
                newState.notes.loading = false;
                newState.notes.error = action.payload?.error;
                newState.notes.currentRequestId = undefined;
            }
        },
    },
});

const {
    setValuationId,
    setActiveCategoryId,
    updateAddress,
    updateDaysRemaining,
    updateMainImage,
    updateVersion,
    updatePropertyDefinition,
    setPropertyDefinitionUpdate,
    setPortfolioUpdate,
    updatePortfolio,
    setClientUpdate,
    updateClient,
    updateRealEstateObjectDefinition,
    setRealEstatePropertyDefinitionUpdate,
    updateTransactions,
    setTransactionsUpdate,
    setIsLoaded,
    updateNote,
    setAreNotesUpdating,
    setUiStyles,
    setIsSuggestionTableRendered,
    setIsSuggestionFlagged,
    setIsAgrimeterModalVisible,
    resetValuation,
} = valuationEdit.actions;
const valuationReducer = valuationEdit.reducer;

export {
    valuationReducer,
    setValuationId,
    valuationApi,
    valuationCategoriesApi,
    setActiveCategoryId,
    updateAddress,
    updateDaysRemaining,
    updateMainImage,
    updateVersion,
    updatePropertyDefinition,
    setPropertyDefinitionUpdate,
    valuationPropertyDefinitionsApi,
    setPortfolioUpdate,
    updatePortfolio,
    updateNote,
    setAreNotesUpdating,
    setClientUpdate,
    updateClient,
    updateRealEstateObjectDefinition,
    setRealEstatePropertyDefinitionUpdate,
    updateTransactions,
    setTransactionsUpdate,
    setIsLoaded,
    setUiStyles,
    setIsSuggestionTableRendered,
    setIsSuggestionFlagged,
    resetValuation,
    valuationRealEstateObjectsApi,
    valuationRealEstateObjectDefinitionsApi,
    valuationApprovalsApi,
    valuationCoiEntriesApi,
    valuationPortfoliosApi,
    valuationClientsApi,
    valuationPredefinedTextApi,
    valuationTransactionsApi,
    valuationSuggestionsApi,
    valuationPropertyNotesApi,
    setIsAgrimeterModalVisible,
};
