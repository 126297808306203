import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import addressFormat from "helpers/addressFormat";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationTransactionsApi = createAsyncThunk(
    "valuationEdit/valuationTransactionsApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile, compView } = getState();

        if (valuationEdit.transactions.loading === false || requestId !== valuationEdit.transactions.currentRequestId)
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const valuationId = valuationEdit?.valuationId;
        const url = defaultConfig.api.valuation.transactions.get.replace("{{valuationId}}", valuationId);

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = response.data;
            const { languageCode } = userProfile?.entities || {};
            const transactionUrl = defaultConfig.path.core.transaction.view;

            return responseData.reduce((resultData, item) => {
                const tempResultData = resultData;
                const city = item?.address?.city;
                const street = addressFormat({ address: item?.address, addCity: false, language: languageCode });
                const type = item?.type?.toLowerCase();
                const color = compView.categories[type]?.options?.color ?? "black";
                const link = transactionUrl.replace("{{id}}", item?.id);
                const image = defaultConfig.api.valuation.transactions.image.replace("{{id}}", item?.id);

                const properties = Object.keys(item.properties).map((key) => {
                    const property = item.properties[key];
                    return {
                        id: property.id,
                        value: property.value,
                        propertyKey: property.propertyDefinition.propertyKey,
                        presentation: property.propertyDefinition.presentation,
                    };
                });
                let sqmPrice;
                let date;
                if (type === "rental") {
                    const rentalSqmPrice = properties.find(
                        (property) =>
                            property.propertyKey === "valuation_value_ri_sqm" ||
                            property.propertyKey === "object_transaction_rental_tenant_ri_sqm"
                    );
                    sqmPrice = {
                        value: rentalSqmPrice?.value,
                        presentation: rentalSqmPrice?.presentation,
                    };

                    date =
                        properties.find(
                            (property) => property.propertyKey === "object_transaction_rental_tenant_startdate"
                        )?.value || null;
                }

                if (type === "investment") {
                    const investmentSqmPrice = properties.find(
                        (property) =>
                            property.propertyKey === "valuation_value_mv_sqm" ||
                            property.propertyKey === "valuation_marketability_purchaseprice_sqm"
                    );
                    sqmPrice = {
                        value: investmentSqmPrice?.value,
                        presentation: investmentSqmPrice?.presentation,
                    };
                    date =
                        properties.find((property) => property.propertyKey === "valuation_date_valuation")?.value ||
                        null;
                }

                if (type === "vacant") {
                    const vacantSqmPrice = properties.find(
                        (property) =>
                            property.propertyKey === "valuation_value_mv_sqm" ||
                            property.propertyKey === "valuation_marketability_purchaseprice_sqm"
                    );
                    sqmPrice = {
                        value: vacantSqmPrice?.value,
                        presentation: vacantSqmPrice?.presentation,
                    };
                    date =
                        properties.find((property) => property.propertyKey === "valuation_date_valuation")?.value ||
                        null;
                }

                const valueObject = {
                    id: item?.id,
                    link,
                    image,
                    address: {
                        city,
                        street,
                    },
                    type,
                    date,
                    sqmPrice,
                    color,
                };
                tempResultData.push(valueObject);
                return tempResultData;
            }, []);
        }

        return rejectWithValue({ error });
    }
);

export default valuationTransactionsApi;
