import LocalStorageCache from "react/scripts/localstoragecache";
import MobileDetect from "mobile-detect/mobile-detect";
import getEnvValue from "helpers/getEnvValue";

const coreBaseUrl = getEnvValue("KATE_CORE_URL");
const readModelBaseUrl = getEnvValue("COMP_VIEW_URL");
const xbrlLegacyAdapterBaseUrl = getEnvValue("XBRL_LEGACY_ADAPTER");
const xbrlFacadeBaseUrl = getEnvValue("XBRL_FACADE");
const wysiwygSSBINUrl = getEnvValue("SSBIN_WYSIWYG_UPLOAD");
const brainbayNLBaseURL = getEnvValue("BRAINBAY_NL");

const defaultConfig = {
    latencyThreshold: getEnvValue("LATENCY_THRESHOLD"),
    userAgent: new MobileDetect(window.navigator.userAgent),
    api: {
        auth: {
            login: `${coreBaseUrl}/api/login`,
            twoFactor: `${coreBaseUrl}/api/login/two-factor`,
            logout: `${coreBaseUrl}/api/logout`,
            resetPassword: `${coreBaseUrl}/new-password`,
        },
        bulkTransition: `${coreBaseUrl}/api/v1/imports/bulk-transition`,
        calculation: {
            calculations: `${coreBaseUrl}/cm/v2/calculationSets`,
            customers: `${coreBaseUrl}/cm/admin/customers`,
            data: `${coreBaseUrl}/cm/calculationSet`,
            statusTransition: `${coreBaseUrl}/cm/updateCalculationSetStatus/{{calculationSetId}}/{{status}}`,
            import: `${coreBaseUrl}/cm/calculationSet/{{calculationSetId}}/import-from-valuation`,
            info: `${coreBaseUrl}/cm/admin/models/docs/index`,
            model: `${coreBaseUrl}/cm/admin/model`,
            models: `${coreBaseUrl}/cm/admin/models`,
            newModel: `${coreBaseUrl}/cm/createCalculationSet`,
            onboard: `${coreBaseUrl}/cm/admin/customers/{{customerId}}/onboard`,
            statuses: `${coreBaseUrl}/cm/calculationSet/{{calculationSetId}}/statuses`,
            unlink: `${coreBaseUrl}/cm/updateCalculationSet/{{calculationSetId}}/unlinkValuation`,
            valuations: `${coreBaseUrl}/cm/valuations`,
        },
        compView: {
            brainbayAgriculture: {
                api: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_OBJECTS_BY_SEARCH")}?connectionType=aenl`,
                formFields: `${getEnvValue("BRAINBAY_BRIDGE_API_CONFIG")}?connectionType=aenl`,
                item: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_DATA")}`,
                login: getEnvValue("BRAINBAY_LOGIN_PAGE"),
                logout: getEnvValue("BRAINBAY_BRIDGE_API_LOGOUT"),
            },
            brainbayBusiness: {
                api: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_OBJECTS_BY_SEARCH")}?connectionType=bog`,
                formFields: `${getEnvValue("BRAINBAY_BRIDGE_API_CONFIG")}?connectionType=bog`,
                item: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_DATA")}`,
                login: getEnvValue("BRAINBAY_LOGIN_PAGE"),
                logout: getEnvValue("BRAINBAY_BRIDGE_API_LOGOUT"),
            },
            brainbayResidential: {
                api: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_OBJECTS_BY_SEARCH")}?connectionType=resi`,
                formFields: `${getEnvValue("BRAINBAY_BRIDGE_API_CONFIG")}?connectionType=resi`,
                item: `${getEnvValue("BRAINBAY_BRIDGE_API_GET_DATA")}`,
                login: getEnvValue("BRAINBAY_LOGIN_PAGE"),
                logout: getEnvValue("BRAINBAY_BRIDGE_API_LOGOUT"),
            },
            brainbayAgricultureV2: {
                api: `${brainbayNLBaseURL}/agriculture/search`,
                formFields: `${brainbayNLBaseURL}/agriculture/filters`,
                item: `${brainbayNLBaseURL}/agriculture/lookup/`,
                login: `${brainbayNLBaseURL}/auth/login`,
                logout: `${brainbayNLBaseURL}/auth/logout`,
            },
            brainbayBusinessV2: {
                api: `${brainbayNLBaseURL}/business/search`,
                formFields: `${brainbayNLBaseURL}/business/filters`,
                item: `${brainbayNLBaseURL}/business/lookup/`,
                login: `${brainbayNLBaseURL}/auth/login`,
                logout: `${brainbayNLBaseURL}/auth/logout`,
            },
            brainbayResidentialV2: {
                api: `${brainbayNLBaseURL}/residential/search`,
                formFields: `${brainbayNLBaseURL}/residential/filters`,
                item: `${brainbayNLBaseURL}/residential/lookup/`,
                login: `${brainbayNLBaseURL}/auth/login`,
                logout: `${brainbayNLBaseURL}/auth/logout`,
            },
            brainbay: {
                logout: getEnvValue("BRAINBAY_BRIDGE_API_LOGOUT"),
            },
            config: `${coreBaseUrl}/api/v1/comp-view/config`,
            field: `${readModelBaseUrl}/api/v1/user/comp-view/field/info`,
            rental: {
                api: `${readModelBaseUrl}/api/v1/user/comp-view`,
                formFields: `${readModelBaseUrl}/api/v1/user/comp-view/fields/info`,
            },
            readModel: {
                api: `${readModelBaseUrl}/api/v1/user/comp-view`,
                formFields: `${readModelBaseUrl}/api/v1/user/comp-view/fields/info`,
                transactionInfoWithParams: `${readModelBaseUrl}/api/v1/user/comp-view?order=lastUpdated:DESC&exact_required=type:transaction&exact_required=active:true&full=true&exact={{subType}}&generic_filter_field=location.city&generic_filter_field=location.street&generic_filter_field=location.street_number&generic_filter_field=location.street_number_additions&generic_filter_field=data.string.valuation_object_type&generic_filter_field=data.string.valuation_address_zip&`,
                valuationInfoWithParams: `${readModelBaseUrl}/api/v1/user/comp-view?order=data.dateTime.valuation_date_deadline_final:ASC&exact_required_negative=status:finished&exact_required_negative=status:declined&exact_required=type:valuation&exact_required=active:true&full=true&generic_filter_field=location.city&generic_filter_field=location.street&generic_filter_field=location.street_number&generic_filter_field=location.street_number_additions&generic_filter_field=data.string.valuation_object_type&generic_filter_field=data.string.valuation_address_zip&generic_filter_field=status&generic_filter_field=data.user.valuation_appraisers_first.profile.shortName&generic_filter_field=data.user.valuation_valuer_plausibility.profile.shortName&generic_filter_field=client_object.name&generic_filter_field=data.double.valuation_value_mv_amount`,
                transactionDataWithParams: `${readModelBaseUrl}/api/v1/user/comp-view/field/info?order=_count:DESC&exact_required_negative=status:finished&exact_required_negative=status:declined&exact_required=type:transaction&exact={{subType}}&page={{page}}&size=200&field={{column}}&type=text`, // replace subtype, page and column
                valuationDataWithParams: `${readModelBaseUrl}/api/v1/user/comp-view/field/info?order=_count:DESC&exact_required=type:valuation&full=true&generic_filter_field=portfolio.name&generic_filter_field=portfolio.number&generic_filter_field=client_object.name&generic_filter_field=data.string.valuation_address_zip&generic_filter_query=&page={{page}}&size=200&field={{column}}&display_field={{displayField}}&type={{type}}`, // change displayField, type, and column
            },
            sales: {
                api: `${readModelBaseUrl}/api/v1/user/comp-view`,
                formFields: `${readModelBaseUrl}/api/v1/user/comp-view/fields/info`,
            },
            table: `${coreBaseUrl}/api/v2/comp-view/config/table`,
            transaction: {
                item: `${coreBaseUrl}/api/v1/transactions/{{id}}/formatted`,
            },
            valuation: {
                api: `${readModelBaseUrl}/api/v1/user/comp-view`,
                item: `${coreBaseUrl}/api/v1/valuations/{{id}}/formatted`,
                formFields: `${readModelBaseUrl}/api/v1/user/comp-view/fields/info`,
            },
        },
        configSettings: `${coreBaseUrl}/api/v1/config-settings`,
        customer: `${coreBaseUrl}/api/v1/customer/info`,
        customImage: {
            sideBarLogo: `${coreBaseUrl}/api/v1/custom-image/customer/{{customerId}}/customerSidebarLogo`,
            missingImage: `${coreBaseUrl}/api/v1/custom-image/customer/{{customerId}}/customerMissingImage`,
            background: `${coreBaseUrl}/api/v1/custom-image/customBackgroundImage`,
            compViewLogo: `${coreBaseUrl}/api/v1/custom-image/customer/{{customerId}}/customerCompViewLogo`,
            favicon: `${coreBaseUrl}/api/v1/custom-image/customFavicon`,
            loginLogo: `${coreBaseUrl}/api/v1/custom-image/customLoginImage`,
        },
        errorMail: `${coreBaseUrl}/api/v1/emails/error-mail`,
        functionality: `${coreBaseUrl}/api/v2/functionality`,
        kira: {
            valuationEdit: `${xbrlLegacyAdapterBaseUrl}/kira/allocateTransfer`,
        },
        menuItems: `${coreBaseUrl}/api/v1/menu-items/`,
        news: `${coreBaseUrl}/api/v1/news`,
        settings: `${coreBaseUrl}/api/v2/settings/`,
        portfolio: `${coreBaseUrl}/api/v1/portfolios/{{portfolioId}}`,
        transaction: {
            import: `${coreBaseUrl}/api/v1/transactions/add_external`,
            addToValuation: `${coreBaseUrl}/api/v1/comp-view/users/valuations/{{valuationId}}/transactions/{{transactionId}}`,
            addToValuationExternal: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/transactions/add_external`,
            export: `${coreBaseUrl}/api/v1/transactions/export`,
            image: `${coreBaseUrl}/api/v1/transactions/{{id}}/image?token={{jwt}}`,
            item: `${coreBaseUrl}/api/v1/transactions/{{id}}/formatted`,
            tables: `${coreBaseUrl}/api/v1/transactions/{{id}}/formatted`,
            readModelSync: `${coreBaseUrl}/api/v1/read-model/transaction/{{id}}`,
        },
        tables: `${coreBaseUrl}/api/v2/dashboard/tables`,
        translations: `${coreBaseUrl}/api/v1/files/translations`,
        userProfile: `${coreBaseUrl}/api/v1/user-profile/`,
        valuation: {
            approvals: {
                get: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/approvals`,
                file: `${coreBaseUrl}{{documentPath}}/{{valuationId}}`,
            },
            categoryWithProperties: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/nested-categories`,
            clients: {
                get: `${coreBaseUrl}/api/v1/clients`,
                post: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/client`,
                statusLegenda: `${coreBaseUrl}/api/v1/clients/status-legenda`,
            },
            coicheck: `${coreBaseUrl}/coicheck/?valuationId={{valuationId}}`,
            coiEntries: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/coientries`,
            coiLink: `${coreBaseUrl}/valuation/{{coiEntryId}}/edit`,
            compView: `${coreBaseUrl}/api/v1/comp-view/users/valuations/`,
            compviewGeoCode: `${coreBaseUrl}/api/v1/addressindex/geocode`,
            exampleReports: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/report-previews`,
            export: `${coreBaseUrl}/api/v1/valuations/export`,
            file: {
                get: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/file/{{filename}}?token={{jwt}}`,
                delete: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/files/{{propertyId}}?size=1&file_1={{filename}}`,
            },
            generatedReports: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/generated-reports`,
            getData: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/info`,
            getDataV1: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}?withValuationProperties=false&withObjects=false&withTransactions=false`,
            getPortfolios: `${coreBaseUrl}/api/v1/portfolios`,
            getProperty: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/property/{{propertyDefinitionId}}`,
            getReport: `${coreBaseUrl}/api/v1/reports/download-generated`,
            getStatusTransitions: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/allowed-transitions`,
            history: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/history`,
            item: `${coreBaseUrl}/api/v1/valuations/{{id}}/formatted`,
            image: {
                default: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/file/{{filename}}?token={{jwt}}`,
                delete: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/files/{{propertyId}}?size=1&file_0={{filename}}`,
                main: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/main-photo?name={{name}}&token={{jwt}}`,
                reOrder: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/files/{{propertyId}}/order/{{fromIndex}}/{{toIndex}}`,
                rotate: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/{{rotateDirection}}/files/{{filename}}`,
            },
            notes: {
                create: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}`, // POST, body: {note: "text"}
                delete: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}/delete`, // POST
                getAll: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}`, // GET
                property: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}`, // GET
                resolve: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}/resolve`, // POST
                subCategory: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/subCategory/{{subCategoryId}}`, // GET
                update: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}/update`, // POST
                unresolve: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/property-definition/{{propertyDefinitionId}}/unresolve`, // POST
                resolveAll: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/resolve-all`, // POST
                overview: `${coreBaseUrl}/api/v1/notes/valuation/{{valuationId}}/overview`, // GET
                toggleResolve: `${coreBaseUrl}/api/v1/notes/{{noteId}}/{{resolveOrUnresolve}}`, // POST change last part with "resolve" or "unresolve"
            },
            object: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/objects/{{objectId}}`,
            postHistory: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/append-history`,
            postPortfolio: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/portfolio`,
            postPropertyValue: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/properties/{{propertyId}}`,
            propertyDefinitions: `${coreBaseUrl}/api/v2/valuations/{{valuationId}}/properties`,
            predefinedText: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/predefined-texts-and-triggers`,
            createPredefinedText: `${coreBaseUrl}/api/v1/predefined-texts/`, // POST
            realEstateObjectDefinitions: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/objects/{{objectId}}/properties?withNestedCategories=true&withValues=true&withPropertyDefinitions=true`,
            realEstateObjectPostProperyValue: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/objects/{{objectId}}/properties/{{propertyId}}?withPropertyDefinition=true&withCategories=true`,
            realEstateObjects: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/objects?withProperties=false`,
            suggestions: {
                get: `${coreBaseUrl}/api/v1/suggestions/valuations/{{valuationId}}`,
                flag: `${coreBaseUrl}/api/v1/suggestions/{{hash}}/{{status}}`, // POST change {{status}} with "flag" or "unFlag"
            },
            transactions: {
                get: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/transactions`,
                image: `${coreBaseUrl}/api/v1/transactions/{{id}}/image?alwaysReturnImage=true&token={{token}}`,
                delete: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/transactions/{{transactionId}}`,
                reOrder: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/order-transactions/{{fromIndex}}/{{toIndex}}`,
            },
            transition: {
                getStatus: `${coreBaseUrl}/api/v1/workflow/transition/{{transitionId}}`,
                getAllowed: `${coreBaseUrl}/api/v1/workflow/allowed-transitions`,
            },
            transitions: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/transitions`,
            xbrlDownload: `${coreBaseUrl}/api/v1/valuations/{{valuationId}}/xbrl/download`,
        },
        workflowsMedia: `${coreBaseUrl}/api/v1/files/workflow-media-files`,
        wysiwyg: `${wysiwygSSBINUrl}/kate-v1/allocateBinaryUpload`,
        xbrl: {
            getFile: `${xbrlFacadeBaseUrl}/{{valuationId}}`,
            getToken: `${xbrlLegacyAdapterBaseUrl}/token`,
            sign: `${xbrlFacadeBaseUrl}/sign/initialize`,
            transfer: `${xbrlFacadeBaseUrl}/transfer`,
        },
        readModel: {
            query: `${readModelBaseUrl}/api/v1/user/comp-view`,
        },
        version: {
            react: `${coreBaseUrl}/api/version/react`,
        },
    },
    coreBaseUrl,
    fields: {
        countryType: "AppBundle\\Form\\Type\\CountryType",
        customCountryType: "AppBundle\\Form\\Type\\CustomCountryType",
        customDateTimeType: "AppBundle\\Form\\Type\\CustomDateTimeType",
        customDateType: "AppBundle\\Form\\Type\\CustomDateType",
        customFileType: "AppBundle\\Form\\Type\\CustomFileType",
        customMoneyType: "AppBundle\\Form\\Type\\CustomMoneyType",
        customNumberType: "AppBundle\\Form\\Type\\CustomNumberType",
        customPercentType: "AppBundle\\Form\\Type\\CustomPercentType",
        customTextArea: "AppBundle\\Form\\Type\\CustomTextareaType",
        customTextType: "AppBundle\\Form\\Type\\CustomTextType",
        dateTimeType: "AppBundle\\Form\\Type\\CustomDateTimeType",
        imageFileType: "AppBundle\\Form\\Type\\ImageFileType",
        fixedTableType: "AppBundle\\Form\\Type\\FixedTableType",
        landRegistryFileType: "AppBundle\\Form\\Type\\LandRegistryFileType",
        moneyType: "AppBundle\\Form\\Type\\MoneyType",
        valueSelectType: "AppBundle\\Form\\Type\\ValueSelectType",
        numberType: "AppBundle\\Form\\Type\\NumberType",
        ownerSelectType: "AppBundle\\Form\\Type\\OwnerSelectType",
        valuationOwnerSelectType: "AppBundle\\Form\\Type\\ValuationOwnerSelectType",
        valuationClientSelectType: "AppBundle\\Form\\Type\\ValuationClientSelectType",
        percentType: "AppBundle\\Form\\Type\\PercentType",
        textAreaType: "AppBundle\\Form\\Type\\TextareaType",
        textType: "AppBundle\\Form\\Type\\TextType",
        userSelectType: "AppBundle\\Form\\Type\\UserSelectType",
        valuationFileType: "AppBundle\\Form\\Type\\ValuationFileType",
    },
    path: {
        react: {
            auth: {
                login: "/auth/login",
                twoFactor: "/auth/login/two-factor",
                logout: "/auth/logout",
                redirectToCore: "/auth/redirectToCore",
            },
            calculationModel: {
                default: "/calculation",
                model: "/calculation/model",
                management: "/calculation/manage",
                onboard: "/calculation/onboard",
                overview: "/calculation/overview",
            },
            error401: "/not-authenticated",
            error403: "/not-authorized",
            error404: "/not-found",
            dashboard: "/dashboard",
            selectEnvironment: "/select-environment",
            debug: "/debug",
            compViewOld: "/comp-view",
            compView: "/compview",
            transaction: "/transaction",
            transactions: "/transactions",
            support: {
                overview: "/support",
                workflows: "/support/workflows",
            },
            valuation: {
                overview: "/valuation",
                edit: "/valuation/edit/{{valuationId}}",
                mobile: "/valuations-mobile/{{valuationId}}/edit",
            },
            portfolio: "/portfolio/{{portfolioId}}/",
            user: {
                profile: "/user/profile",
            },
            batchActions: "/batch-actions",
            error: (type) => {
                let errorUrl;
                switch (type) {
                    case 401:
                        errorUrl = "/not-authenticated";
                        break;
                    case 403:
                        errorUrl = "/not-authorized";
                        break;
                    case 404:
                        errorUrl = "/not-found";
                        break;
                    default:
                        errorUrl = "/dashboard?error=true";
                        break;
                }

                return errorUrl;
            },
        },
        readModel: {
            base: getEnvValue("COMP_VIEW_URL"),
            query: "/api/v1/user/comp-view",
            idOnly: "/api/v1/user/comp-view/id-only",
        },
        core: {
            base: coreBaseUrl,
            dashboard: {
                base: "/api/desktop",
                clients: "/api/v1/dashboard/clients",
                portfolio: "/api/v1/dashboard/portfolios",
                transaction: "/api/v1/dashboard/transactions",
                valuation: "/api/v1/dashboard/valuations",
            },
            comp_view: {
                valuations: "/api/v1/comp-view/users/valuations",
                link_transaction_to_valuation(valuationId, transactionId) {
                    return `/api/v1/comp-view/users/valuations/${valuationId}/transactions/${transactionId}`;
                },
            },
            valuation: {
                propertyDefinitionsConfig: "/api/v1/properties/config/valuation/",
                previewExamplePDF: `${coreBaseUrl}/report/{{valuationId}}/pdf/{{reportPreview}}`,
                coreViewLink: `${coreBaseUrl}/valuation/{{valuationId}}`,
                downloadReportPDF: `${coreBaseUrl}/valuation/{{valuationId}}/download/{{fileName}}/{{downloadName}}`,
            },
            login: "/api/login",
            logout: `${coreBaseUrl}/logout`,
            news: "/api/v1/news",
            jwt: "/jwt",
            user_profile: "/api/v1/user-profile/",
            users: "/api/v1/users/",
            configSettingsImmutable: "/api/v1/config-settings/",
            transaction: {
                create: `${coreBaseUrl}/transaction/new`,
                edit: (id) => {
                    return `${coreBaseUrl}/transaction/${id}/edit`;
                },
                photo: (id, token) => {
                    return `${coreBaseUrl}/api/v1/transactions/${id}/image?token=${token}`;
                },
                view: `${coreBaseUrl}/transaction/{{id}}`,
                report: `${coreBaseUrl}/report/{{id}}/transactionpdf/transaction`,
            },
            menuItems: `${coreBaseUrl}/api/v1/menu-items/`,
            transactions(id) {
                return `${coreBaseUrl}/api/v1/transactions/${id}`;
            },

            transactionPhoto(id, token) {
                return `${coreBaseUrl}/api/v1/transactions/${id}/image?token=${token}`;
            },
            redirectDesktop(token) {
                return `${coreBaseUrl}/api/desktop?token=${token}`;
            },
            translations: `${coreBaseUrl}/api/v1/files/translations`,
            portfolio: {
                new: `${coreBaseUrl}/portfolio/new`,
            },
            client: {
                new: `${coreBaseUrl}/client/new`,
                view: `${coreBaseUrl}/client/{{clientId}}`,
            },
        },
        menuItems: `${coreBaseUrl}/api/v1/menu-items/`,
        userProfile: `${coreBaseUrl}/api/v1/user-profile/`,
        transaction: {},
    },
    settings: {
        currency: {
            code: getEnvValue("CURRENCY_CODE") || "EUR",
            symbol: getEnvValue("CURRENCY_SYMBOL") || "€",
        },
        wysiwyg(language) {
            return {
                language,
                toolbarCanCollapse: false,
                toolbar: [
                    { name: "basicstyles", items: ["Bold", "Italic"] },
                    { name: "paragraph", items: ["NumberedList", "BulletedList", "-", "Outdent", "Indent", "-"] },
                    { name: "links", items: ["Link", "Unlink"] },
                ],
            };
        },
        support: {
            url: getEnvValue("CUSTOMER_SUPPORT_URL"),
            messageUrl: getEnvValue("CUSTOMER_SUPPORT_MESSAGE_URL"),
            email: getEnvValue("CUSTOMER_SUPPORT_EMAIL"),
            phoneNumber: getEnvValue("CUSTOMER_SUPPORT_PHONE_NUMBER"),
            phonePresentation: getEnvValue("CUSTOMER_SUPPORT_PHONE_PRESENTATION"),
        },
        passwordExpiration: {
            expirationInterval: getEnvValue("PASSWORD_EXPIRATION_WARNING_INTERVAL"),
        },
    },
    storage: {
        layout(module) {
            return new LocalStorageCache("app", `layout-${module}`);
        },
        configSettingsImmutable: new LocalStorageCache("data", "configSettingsImmutable"),
        propertyDefinitionsConfig: new LocalStorageCache("data", "valuation-property-definition-config"),
        compViewFieldsMeta: new LocalStorageCache("data", "comp-view-fields-meta"),
        axios(id) {
            return new LocalStorageCache("axios", `axios-${id.toString()}`);
        },
        valuation(id) {
            return new LocalStorageCache("data", `valuation-${id}`);
        },
        dashboard(module) {
            return new LocalStorageCache("data", `dashboard-${module}`);
        },
        compView(id) {
            return new LocalStorageCache("data", `comp-view-${id.toString()}`);
        },
        support() {
            return new LocalStorageCache("data", `support`);
        },
    },
    styling: {
        breakpoints: {
            lg: 1280,
            md: 996,
            sm: 768,
            xs: 480,
            xxs: 0,
        },
    },
    toastr: {
        timeOut: 4000,
    },
};

const reactUrls = defaultConfig.path.react;

export default defaultConfig;
export { reactUrls };
