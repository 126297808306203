import React, { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";
import loadable from "@loadable/component";
import ReduxToastr from "react-redux-toastr";
import TranslationsProvider from "features/translations/TranslationsProvider";
import AuthProvider from "features/authentication/AuthProvider";
import ScrollToTop from "features/ScrollToTop";
import Loading from "@kateinnovations/ui-library/Loading";
import { getUserLanguage } from "features/userProfile/userProfileSelector";
import defaultPageData from "constants/defaultPageData";
import configInit from "config";
import defaultConfig from "config/constants/defaultConfig";

const Template = loadable(() => import(/* webpackChunkName: "Template" */ "./Template"), {
    fallback: <Loading />,
});

const TemplateApplication = () => {
    const languageCode = useSelector(getUserLanguage);
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);
    const { htmlAttribute } = defaultPageData;

    useEffect(() => {
        const setConfig = async () => {
            const response = await configInit();
            if (response.ok) setIsConfigLoaded(true);
        };

        setConfig();
    }, [isConfigLoaded]);

    if (!isConfigLoaded) return <Loading />;

    return (
        <Router>
            <AuthProvider>
                <TranslationsProvider>
                    <>
                        <Helmet
                         style={[
                            {
                                cssText: `
                                html {
                                    background-image: linear-gradient(rgba(0, 91, 134, 0.85), rgba(0, 44, 63, 0.85)), url("${defaultConfig.api.customImage.background}")
                                }
                            `,
                            },
                        ]}
                        >
                           <link rel="shortcut icon" href={defaultConfig.api.customImage.favicon}/>
                            <html
                                lang={languageCode}
                                itemScope={htmlAttribute.itemscope}
                                itemType={htmlAttribute.itemtype}
                            />
                        </Helmet>
                        <Suspense fallback={<Loading />}>
                            <Router>
                                <ScrollToTop />
                                <Template />
                            </Router>
                        </Suspense>
                        <ReduxToastr
                            timeOut={defaultConfig.toastr.timeOut}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-left"
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar
                        />
                    </>
                </TranslationsProvider>
            </AuthProvider>
        </Router>
    );
};

export default TemplateApplication;
