/* eslint-disable import/no-cycle */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError, externalAuth } from "features/authentication/authenticationSlice";
import {
    apiCall,
    validations,
    getLatLngByAddress,
    ucFirst,
    capitalizeFirstLetterWord,
    capitalize,
    getLatLngCenterPoint,
    getDistanceBetweenPoints,
    removeStorageItem,
} from "@kateinnovations/javascript-helpers";
import { addressFormat } from "helpers";
import applicationErrorMessage from "helpers/applicationErrorMessage";
import defaultConfig from "config/constants/defaultConfig";
import { toastr } from "react-redux-toastr";
import serializeCompViewData from "./helpers/serializeCompViewData";
import { generateSearchParams } from "./generateSearchParams";
import { createDateCustomFilter } from "./helpers/generateFilterParams";
import v2TokenApi from "../v2/v2TokenApi";
import { isV2TokenExpired } from "../../helpers/v2TokenHandler";
import { setIsBrainbayV2LoggedIn } from "./compViewSlice";

const compViewApi = createAsyncThunk(
    "compView/compViewApi",
    async ({ category, cancel = false }, { dispatch, getState, signal, rejectWithValue }) => {
        const { auth, compView, userProfile, translations, configSettings, v2 } = getState();
        const googleAPIKey = configSettings?.entities?.reactConfig?.googleWebMapApiKey;
        if (cancel) return signal.aborted();
        const error = ["AUTHENTICATION_FAILED"];
        const language = userProfile?.entities?.languageCode;
        const {
            id: categoryId,
            type,
            subType,
            isExternalSource,
            disabled,
            hidden,
            isV2Brainbay,
        } = compView.categories[category];
        if (disabled || hidden) return undefined;
        const { jwt } = auth || {};
        const formFilters = compView.form[category] || [];
        const filters = compView.filter;
        const isBrainbayV2Connection = isV2Brainbay ?? false;
        const messages = translations.entities;

        let color = null;
        switch (category) {
            case "valuation":
                color = "#67c88c";
                break;
            case "brainbayAgriculture":
            case "brainbayAgricultureV2":
                color = "#009f4d";
                break;
            case "brainbayBusiness":
            case "brainbayBusinessV2":
                color = "#0095c8";
                break;
            case "brainbayResidential":
            case "brainbayResidentialV2":
                color = "#ff466e";
                break;
            case "lonres":
                color = "#d90070";
                break;
            default:
                color = compView.categories[category].options?.color;
        }

        if (!jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        let headers = {};
        let url = defaultConfig.api.compView[category]?.api ?? defaultConfig.api.compView.readModel.api;
        let method = "POST";
        let credentials = "include";
        let body = {};

        if (isExternalSource) {
            headers["content-type"] = "application/x-www-form-urlencoded";

            const latLngPoints = filters.geo_box_filter.split(";");
            const latlon = getLatLngCenterPoint(latLngPoints).replace(/,/i, "|");
            const radius = getDistanceBetweenPoints(latLngPoints) / 2;

            const externalFilter =
                formFilters.reduce((data, item) => {
                    const tempData = data;
                    const searchParamKey = item.searchParam;

                    if (item.value && item.elementType === "range" && item.disabled === false) {
                        const valueSplitted = item.value.split(";");
                        const valueFrom = valueSplitted[0];
                        const valueTill = valueSplitted[1];
                        tempData[`${searchParamKey}van`] = valueFrom;
                        tempData[`${searchParamKey}tot`] = valueTill;
                        return tempData;
                    }
                    if (item.value && item.disabled === false) {
                        tempData[searchParamKey] = item.value || "";
                        return tempData;
                    }

                    return tempData;
                }, {}) || {};
            body = {
                latlonstraal: `${latlon}|${radius}`,
                ...externalFilter,
                // Take: 50, // possible to make brainbay faster if it still needs to cache a lot of addresses.
            };
            if (isBrainbayV2Connection) {
                const filtersV2 = body;
                const errorMessage = ucFirst(
                    messages["compview.form.invalidFilter.postcode"] ??
                        "the filter postcode(from/to) is invalid, it only allows for 4 numbers and 2 letters(1234 AB). Invalid filters are not applied."
                );
                const isPostCodeValid = (postCode) => {
                    const numbersCount = postCode.match(/[0-9]/gi)?.length ?? 0;
                    const lettersCount = postCode.match(/[a-zA-Z]/gi)?.length ?? 0;
                    return !(numbersCount > 4 || lettersCount > 2);
                };
                if ((body?.postcodevan ?? null) !== null && !isPostCodeValid(body?.postcodevan)) {
                    toastr.error(ucFirst(errorMessage), { timeOut: 8000 });
                    delete body.postcodevan;
                }
                if ((body?.postcodetot ?? null) !== null && !isPostCodeValid(body?.postcodetot)) {
                    toastr.error(ucFirst(errorMessage), { timeOut: 8000 });
                    delete body.postcodevan;
                }
                body = { filters: JSON.stringify(filtersV2) };
                headers = {
                    "content-type": "application/x-www-form-urlencoded",
                    authorization: `Bearer ${
                        isV2TokenExpired(v2) ? (await dispatch(v2TokenApi()))?.payload?.token : v2?.token
                    }`,
                };
                credentials = "omit";
            }
        }

        if (!isExternalSource) {
            method = "GET";
            credentials = "omit";
            headers.authorization = `Bearer ${jwt}`;

            let filterParams = `?exact_required=type:${type || ""}&exact_required=subType:${
                subType || ""
            }&exact_required=compview:true&exact_required=active:true`;

            const searchParams = generateSearchParams(filters);

            formFilters.forEach((filter) => {
                if (filter.disabled === true) return;

                if (filter.searchParam === "query") {
                    const t = `&generic_filter_field=${filter.name}&generic_filter_query=${filter.value || ""}`;
                    filterParams += t;
                    return true;
                }

                let customFilterValue = null;
                if (filter.elementType === "date" && filter.searchParam.includes("range")) {
                    customFilterValue = createDateCustomFilter(filter.value);
                }

                const value = `&${filter.searchParam}=${filter.name}:${customFilterValue || filter.value || ""}`;
                filterParams += value;
            });
            const apiUrl = defaultConfig.api.compView[category]?.api ?? defaultConfig.api.compView.readModel.api;
            url = `${apiUrl}${filterParams}&${searchParams.toString()}&all=true`;
        }

        const getResponse = await apiCall({
            url,
            method,
            headers,
            cache: "default",
            credentials,
            mode: "cors",
            signal,
            body,
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";
        const isTextPlainResponse = contentType === "text/plain";

        if (getResponse.status === 400 && isExternalSource) {
            // implementation error, hence we want to be informed
            applicationErrorMessage({ jwt, getResponse: await getResponse.json(), url });
            return [];
        }
        if (getResponse.status === 400) return [];

        if ([401, 403].includes(getResponse.status)) {
            if (!isExternalSource) dispatch(setAuthError("authenticationError"));
            if (getResponse.status === 401 && isBrainbayV2Connection) dispatch(setIsBrainbayV2LoggedIn(false));
            if (isExternalSource && !isBrainbayV2Connection) {
                const sourceType = category.startsWith("brainbay") ? "brainbay" : category;
                const logoutUrl = defaultConfig.api.compView[sourceType].logout;
                await apiCall({
                    url: logoutUrl,
                    headers,
                    method,
                    credentials,
                    mode: "cors",
                });
                removeStorageItem(`${sourceType}Token`, "localStorage");
                const errorMessage = ucFirst(
                    messages["compview.externalSourceManager.error.message.sessionExpired"]?.replace(
                        /%category%/g,
                        sourceType
                    )
                );
                toastr.error(ucFirst(errorMessage));
                dispatch(
                    externalAuth({
                        [sourceType]: undefined,
                    })
                );
            }
            return rejectWithValue({ error });
        }

        if (getResponse.ok && isJsonResponse && !isExternalSource) {
            const response = await getResponse.json();
            const responseData = await response.reduce(async (data, item) => {
                if ((item.active && !item.compview) || !item.active) return data;

                const tempData = await data;
                const address = {
                    city: item.location?.city?.trim(),
                    country: item.location?.country?.trim(),
                    postalCode: item.location?.postal_code?.toString()?.replace(/\s+/g, "")?.toUpperCase(),
                    street: item.location?.street?.trim(),
                    number: item.location?.street_number,
                    addition: item.location?.street_number_additions?.trim(),
                };
                const addressAsString = addressFormat({ address, language });
                const addressCityFirstAsString = addressFormat({ address, language, cityIsFirstItem: true });
                const titleForSort = addressFormat({ address, language, cityIsFirstItem: true, forSort: true });
                const latLng = {
                    lat: item.geolocation?.lat || null,
                    lng: item.geolocation?.lon || item.geolocation?.lng || null,
                };

                if (validations.isEmpty(item.geolocation)) {
                    const addressForLatLng = {
                        street: ucFirst(item.location?.street),
                        street_number: item.location?.street_number,
                        street_number_additions: item.location?.street_number_additions,
                        postal_code: item.location?.postal_code?.toString()?.replace(/\s+/g, "")?.toUpperCase(),
                        city: capitalizeFirstLetterWord(capitalize(item.location?.city)),
                        country: item.location.country,
                    };

                    const latLngFromAddress = await getLatLngByAddress({
                        addressForLatLng,
                        googleAPIKey,
                        prefixStorageKey: "kate",
                    });

                    latLng.lat = latLngFromAddress.lat.toString();
                    latLng.lng = latLngFromAddress.lng.toString();
                }

                const currentObject = {
                    id: item.id,
                    title: addressAsString,
                    titleCityFirst: addressCityFirstAsString,
                    titleForSort,
                    latLng,
                    subType: item.subType?.toLowerCase(),
                    type: item.type?.toLowerCase(),
                    categoryId,
                    color,
                    category,
                    dateCreated: item.dateCreated,
                };

                tempData.push(currentObject);

                return tempData;
            }, []);
            return responseData;
        }

        if (getResponse.ok && isExternalSource) {
            const externalResponse = (isTextPlainResponse || isJsonResponse) && (await getResponse.json());
            const responseContent = !isBrainbayV2Connection ? externalResponse : externalResponse.result;
            return responseContent.reduce(async (data, item) => {
                const tempData = await data;
                const convertedData = await serializeCompViewData.brainbayBulk({
                    dataObject: item,
                    categoryId,
                    category,
                    color,
                    language,
                    googleAPIKey,
                });

                tempData.push(convertedData);

                return tempData;
            }, []);
        }
        applicationErrorMessage({ jwt, getResponse: await getResponse.json(), url });

        return rejectWithValue(["APPLICATION_ERROR"]);
    }
);

export default compViewApi;
